import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  amount: 0,
  confirm: false
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {

    increase: (state) => { state.amount += 10 },
    decrease: (state) => { if (state.amount > 0) state.amount -= 10 },
    setTips: (state, action) => { if (action.payload >= 0) state.amount = action.payload },
    setConfirm: (state, action) => { state.confirm = action.payload }

  },
})

export const {
  increase,
  decrease,
  setTips,
  setConfirm
} = globalSlice.actions

export default globalSlice.reducer