import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  confirmItinerary: false,
  confirmPassengerDetails: false,
  confirmAcceptedTerms: false,
}

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmItinerary: (state, action) => { state.confirmItinerary = action.payload },
    setConfirmPassengerDetails: (state, action) => { state.confirmPassengerDetails = action.payload },
    setConfirmAcceptedTerms: (state, action) => { state.confirmAcceptedTerms = action.payload },
  },
})

export const {
  setConfirmItinerary,
  setConfirmPassengerDetails,
  setConfirmAcceptedTerms,
} = confirmationSlice.actions

export default confirmationSlice.reducer