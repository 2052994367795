import { configureStore } from '@reduxjs/toolkit'

import passengerReducer from './passenger'
import billingReducer from './billing'
import tipsReducer from './tips'
import quoteReducer from './quote'
import agentReducer from './agent'
import contactReducer from './contact'
import confirmationReducer from './confirmation'

export const store = configureStore({
  reducer: {
    passenger: passengerReducer,
    quote: quoteReducer,
    billing: billingReducer,
    tips: tipsReducer,
    agent: agentReducer,
    contact: contactReducer,
    confirmation: confirmationReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})