import { useEffect } from 'react'
import '../styles/global.sass'
import '../components/UI/PriceSelector/index.css'
import "../styles/navigation.css"
import "../styles/calendar.css"
import "../styles/tel.css"
import Head from 'next/head'

// redux
import { store } from '../store'
import { Provider } from 'react-redux'

// 
import '../i18n'

export default function MyApp({ Component, pageProps }) {

  if (typeof window !== "undefined") {
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, [])

  // TODO: Delete
  if (true) {
    console.log('123');
  }

  return (
    <Provider store={store}>
      <Head>
        <script id="ms-clarity-script"
          dangerouslySetInnerHTML={{
            __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "hg77drljvu");
                  `,
          }}
        ></script>
      </Head>
      <Component {...pageProps} />
    </Provider>
  )
}

// MyApp.getInitialProps = async ({ Component, ctx }) => {
//   let pageProps = {}
//   if (Component.getInitialProps) {
//     pageProps = await Component.getInitialProps(ctx)
//   }
//   return { pageProps }
// }