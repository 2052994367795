import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  card: {
    number: '',
    holder: '',
    expiration: '',
    cvv: '',

    lengths: {
      number: 16,
      cvv: 3
    }
  },
  address: {
    country: '',
    state: '',
    street: '',
    city: '',
    zip: ''
  },
  contact: {
    email: "",
    phone: "",
    countryCode: "",
  },
  countries: [],
  regions: [],
}

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {

    setNumber: (state, action) => { state.card.number = action.payload },
    setHolder: (state, action) => { state.card.holder = action.payload },
    setExpiration: (state, action) => { state.card.expiration = action.payload },
    setCvv: (state, action) => { state.card.cvv = action.payload },

    setLengthNumber: (state, action) => { state.card.lengths.number = action.payload },
    setLengthCvv: (state, action) => { state.card.lengths.cvv = action.payload },

    setBillingCountry: (state, action) => { state.address.country = action.payload },
    setBillingState: (state, action) => { state.address.state = action.payload },
    setBillingStreet: (state, action) => { state.address.street = action.payload },
    setBillingCity: (state, action) => { state.address.city = action.payload },
    setBillingZip: (state, action) => { state.address.zip = action.payload },

    setBillingContactEmail: (state, action) => { state.contact.email = action.payload },
    setBillingContactPhone: (state, action) => { state.contact.phone = action.payload },
    setBillingContactCountryCode: (state, action) => { state.contact.countryCode = action.payload },

    setCountries: (state, action) => { state.countries = action.payload },
    setRegions: (state, action) => { state.regions = action.payload }
  },
})

export const {
  setNumber,
  setHolder,
  setExpiration,
  setCvv,
  setLengthNumber,
  setLengthCvv,

  setBillingCountry,
  setBillingState,
  setBillingStreet,
  setBillingCity,
  setBillingZip,

  setBillingContactEmail,
  setBillingContactPhone,
  setBillingContactCountryCode,
  
  setCountries,
  setRegions
} = billingSlice.actions

export default billingSlice.reducer