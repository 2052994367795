import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  trips: [],
  selectedTrip: {},
  offerUUID: null
}

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setTrips: (state, action) => { state.trips = action.payload },
    setSelectedTrip: (state, action) => { state.selectedTrip = action.payload },
    setOfferUUID: (state, action) => { state.offerUUID = action.payload },
  },
})

export const {
  setTrips,
  setSelectedTrip,
  setOfferUUID
} = quoteSlice.actions

export default quoteSlice.reducer