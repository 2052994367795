import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email: "",
  phone: "",
  countryCode: "",
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setEmail: (state, action) => { state.email = action.payload },
    setPhone: (state, action) => { state.phone = action.payload },
    setCountryCode: (state, action) => { state.countryCode = action.payload }
  },
})

export const {
  setEmail,
  setPhone,
  setCountryCode
} = contactSlice.actions

export default contactSlice.reducer