import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  info: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  }
}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAgent: (state, action) => { state.info = action.payload}
  },
})

export const {
  setAgent,
} = agentSlice.actions

export default agentSlice.reducer