import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paxes: []
}

export const passengerSlice = createSlice({
  name: 'passenger',
  initialState,
  reducers: {
    setPaxes: (state, action) => { state.paxes = action.payload},

    setProtect: (state, action) => { state.paxes[action.payload.id].protect = action.payload.value },
    setProtectAll: (state, action) => { state.paxes.forEach(el => el.protect = action.payload) },

    setName: (state, action) => { state.paxes[action.payload.id].firstName = action.payload.value },
    setMiddleName: (state, action) => { state.paxes[action.payload.id].middleName = action.payload.value },
    setLastName: (state, action) => { state.paxes[action.payload.id].lastName = action.payload.value },

    setBmonth: (state, action) => { state.paxes[action.payload.id].dob.month = action.payload.value },
    setBday: (state, action) => { state.paxes[action.payload.id].dob.day = action.payload.value },
    setByear: (state, action) => { state.paxes[action.payload.id].dob.year = action.payload.value },

    setGender: (state, action) => { state.paxes[action.payload.id].gender = action.payload.value },

    setFrequest: (state, action) => { state.paxes[action.payload.id].frequest = action.payload.value }

  },
})

export const {
  setPaxes,
  
  setProtect,
  setProtectAll,

  setName,
  setMiddleName,
  setLastName,

  setBmonth,
  setBday,
  setByear,

  setGender,

  setFrequest
} = passengerSlice.actions

export default passengerSlice.reducer